import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import facebook from "../assets/images/facebook-icon.svg";
import youtube from "../assets/images/youtube-icon.svg";
import twitter from "../assets/images/twitter-icon.svg";
import instagram from "../assets/images/instagram-icon.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import GlobalContext from "../GlobalContext";
import OutsideAlerter from "./OutsideAlerter";
import { useTranslation } from "react-i18next";

const Layout = (props) => {
  const gtx = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  // menu items
  const menuItems = [
    { name: "home_page", path: `/${i18n.language}` },
    // { name: "about", path: "/about" },
    { name: "products", path: `/${i18n.language}/products` },
    { name: "contact_us", path: `/${i18n.language}/contact-us` },
  ];

  const socialMedia = [
    { icon: facebook, url: "https://www.facebook.com" },
    { icon: instagram, url: "https://www.instagram.com" },
    { icon: twitter, url: "https://www.twitter.com" },
    { icon: whatsapp, url: " https://wa.me/34601536568" },
    { icon: youtube, url: "https://www.youtube.com" },
  ];

  const currentYear = new Date().getFullYear();

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (gtx.loading === true) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflowX = "hidden";
      document.getElementsByTagName("html")[0].style.overflowY = "unset";
    }
  }, [gtx.loading]);

  const initialLanguage = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en";

  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  const languageSwitchHandler = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    navigate("/" + lang + location.pathname.slice(3));
    setMenuOpen(false);
  };

  useEffect(() => {
    localStorage.setItem("language", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Fragment>
      {/* Desktop menu */}
      <nav className="custom-navbar d-lg-block d-none">
        <div className="container-fluid position-relative">
          <div className="row pt-4 justify-content-between align-items-center">
            <div className="col-lg-2 col-md-3 col-sm-4 pe-lg-5">
              <Link to={`/${i18n.language}`}>
                <div className="ratio ratio-logo pe-xl-5 logo-max-w-header">
                  <img src={logo} alt="logo" className="contain" />
                </div>
              </Link>
            </div>
            <div className="col-lg-2 d-flex justify-content-end">
              {/* <select
                type="select"
                onChange={(e) => console.log(e.target.value)}
                className="language-switch"
              >
                <option>English</option>
                <option>Espanol</option>
                <option>Français</option>
              </select> */}
              <ul className="d-flex languages">
                <li
                  onClick={() => languageSwitchHandler("en")}
                  className={`px-2 ${
                    selectedLanguage === "en" ? "active" : ""
                  }`}
                >
                  English
                </li>
                <li
                  onClick={() => languageSwitchHandler("es")}
                  className={`px-2 ${
                    selectedLanguage === "es" ? "active" : ""
                  }`}
                >
                  Español
                </li>
                <li
                  onClick={() => languageSwitchHandler("fr")}
                  className={`px-2 ${
                    selectedLanguage === "fr" ? "active" : ""
                  }`}
                >
                  Français
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid menu-margin">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-7 zindex-10">
              <ul className=" d-flex mb-0 menu-items px-0 justify-content-center">
                {menuItems.map((link, index) => (
                  <li
                    key={index}
                    className={`menu-item d-flex justify-content-center ${
                      index < menuItems.length - 1 ? "me-3" : ""
                    } 
                    ${link.name === props.active ? "active" : ""}`}
                  >
                    <Link
                      to={link.path}
                      className="text-center text-uppercase px-2 pt-2"
                    >
                      {t(`menu.${link.name}`)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      <nav className="d-lg-none">
        <div className="container-fluid text-center px-0 d-flex flex-column align-items-center">
          <Link className="my-4" to={`/${i18n.language}`}>
            <div className="ratio ratio-logo logo-header-mob">
              <img src={logo} alt="logo" className="contain" />
            </div>
          </Link>
          <div className="menu-title-wrapper-mob w-100">
            <div className="container-fluid">
              <div className="row position-relative">
                <p className="menu-title-mob mb-0 py-2 text-uppercase">
                  {t(`menu.${props.active}`)}
                </p>
                <OutsideAlerter closeMenu={() => setMenuOpen(false)}>
                  <div className="position-absolute top-50 end-0 translate-middle-y w-fit me-2">
                    <div
                      onClick={() => setMenuOpen(!menuOpen)}
                      id="nav-icon"
                      className={`${menuOpen ? "open" : ""}`}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div
                    className={`menu-wrapper-mob me-2 ${
                      menuOpen ? "show" : ""
                    }`}
                  >
                    <div className="menu-pointer"></div>
                    <div className="menu-dropdown">
                      <ul>
                        {menuItems.map((link, index) => (
                          <li
                            className={`${index !== 0 ? "pt-3" : ""}`}
                            key={index}
                          >
                            <Link
                              to={link.path}
                              className={
                                link.name === props.active
                                  ? "mobnav-active"
                                  : ""
                              }
                            >
                              {t(`menu.${link.name}`)}
                            </Link>
                          </li>
                        ))}
                      </ul>
                      <ul className="d-flex languages">
                        <li
                          onClick={() => languageSwitchHandler("en")}
                          className={`px-2 ${
                            selectedLanguage === "en" ? "active" : ""
                          }`}
                        >
                          English
                        </li>
                        <li
                          onClick={() => languageSwitchHandler("es")}
                          className={`px-2 ${
                            selectedLanguage === "es" ? "active" : ""
                          }`}
                        >
                          Español
                        </li>
                        <li
                          onClick={() => languageSwitchHandler("fr")}
                          className={`px-2 ${
                            selectedLanguage === "fr" ? "active" : ""
                          }`}
                        >
                          Français
                        </li>
                      </ul>
                    </div>
                  </div>
                </OutsideAlerter>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {props.children}
      {/* Footer */}
      <footer className="footer">
        <div className="container-fluid py-3">
          <div className="row justify-content-lg-between justify-content-center">
            <div className="col-lg-9">
              <div className="row align-items-center justify-content-md-start justify-content-center h-100">
                <div className="col-sm-2 col-3 pb-md-0 pb-3 my-auto logo-footer pe-xl-5 pe-lg-4">
                  <div className="ratio ratio-logo mx-md-0 mx-auto logo-max-w-footer pe-xxl-5 pe-xl-4 pe-lg-3 pe-md-5">
                    <img src={logo} alt="logo" className="contain" />
                  </div>
                </div>
                <div className="col-md-10 col-11 px-sm-2 px-1 footer-copyrights ps-xl-5 ps-lg-4 ps-md-4 my-auto pt-md-0 pt-4 text-md-start text-center">
                  <p>
                    {/* This website and its contents are copyright &#169;&nbsp;
                    {currentYear}&nbsp; CAPITANA ET HIJOS */}
                    {t("footer.copyrights")} &#169;&nbsp;
                    {currentYear}&nbsp; CAPITANA ET HIJOS
                  </p>
                  <p className="pt-2">
                    CAPITANA ET HIJOS - Maestro falla, 10 - 41006 Sevilla (
                    Sevilla ) YYS9E3ADPCLVNWP
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-8 col-9 d-flex align-items-center justify-content-center justify-content-lg-end pt-lg-0 pt-4">
              <div className="row justify-content-center">
                {socialMedia.map((link, index) => (
                  <div
                    key={index}
                    className={`col-3 d-flex justify-content-center ${
                      index > 3 ? "pt-3" : ""
                    }`}
                  >
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                      className="menu-item-mob ratio ratio-1x1"
                    >
                      <img src={link.icon} alt="icon" className="contain" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Loader */}
      {gtx.loading && (
        <div className="position-fixed top-0 start-0 vw-100 vh-100 lightgray-bg front">
          <div className="position-relative h-100 w-100 d-flex justify-content-center align-items-center">
            <div id="wrapper">
              <div
                className="gears d-flex justify-content-center"
                id="two-gears"
              >
                <div className="gears-container">
                  <div className="gear-rotate"></div>
                  <div className="gear-rotate-left"></div>
                </div>
              </div>
            </div>
            <div className="gears-try">
              <i></i>
              <i></i>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Layout;
