export const French = {
  menu: {
    home_page: "page d'accueil",
    products: "Produits",
    contact_us: "Contactez-nous",
    not_found: "Page non trouvée (404)",
  },
  products: {
    noProd: "Aucun produit trouvé!"
  },
  contactPage: {
    name: "Contactez-Nous",
    firstNameTitle: "Prénom",
    lastNameTitle: "Nom",
    emailTitle: "e-mail",
    phoneNumberTitle: "numéro de téléphone",
    messageTitle: "Message",
    firstNameErrorText: "Entrez votre prénom s'il vous plait",
    lastNameErrorText: "Entrez votre nom s'il vous plait",
    emailErrorText: "Veuillez entrer un email valide",
    phoneNumberErrorText:
      "S'il vous plaît entrer un numéro de téléphone valide",
    messageErrorText: "Veuillez entrer un message",
    sendMessageTitle: "Envoyer le message",
    successMessage: "Votre message a bien été envoyé!",
    phonePlaceholder: "Entrez votre numéro de téléphone",
  },
  footer: {
    copyrights:
      "Ce site Web et son contenu sont protégés par le droit d'auteur",
  },
  map: {
    location: "notre emplacement en",
  },
  search: {
    placeHolder: "Chercher...",
  },
};
