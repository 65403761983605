import "aos/dist/aos.css";
import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/Layout";
import AOS from "aos";
import axios from "axios";
import { Link } from "react-router-dom";
import GlobalContext from "../GlobalContext";
import CategoriesCard from "../components/CategoriesCard";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const ProductsCategories = () => {
  const [data, setData] = useState([]);
  const gtx = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(gtx.loading);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "category")
      .then((res) => {
        setData(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        throw err;
      });
  }, []);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (showLoader === true) {
      gtx.setLoading(true);
    } else {
      gtx.setLoading(false);
    }
  }, [showLoader, gtx]);

  return (
    <Layout active="products">
      <Helmet>
        <title>{t("menu.products").toUpperCase()}</title>
      </Helmet>
      <div className="container flex-grow-1">
        <div className="row justify-content-center pb-5">
          {/* <div className="col-lg-4 col-md-6 px-sm-4 px-3">
            <form onSubmit={searchSubmitHandler} className="position-relative">
              <input
                className="search-bar py-1 ps-5 pe-1 w-100"
                type="text"
                placeholder="SEARCH..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
              <button
                type="submit"
                className="position-absolute top-0 start-0 h-100 search-button-prods ms-2 search-button-w"
              >
                <div className="ratio ratio-1x1 w-100">
                  <img src={searchIcon} alt="search" className="contain" />
                </div>
              </button>
            </form>
          </div> */}
          <div
            data-aos="fade-right"
            data-aos-duration="800"
            className="row gx-sm-4 gx-lg-5 gx-3 pt-4"
          >
            {data.map((link, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 pt-4" key={index}>
                <Link
                  className="text-decoration-none d-flex flex-column h-100 single-prod-link rounded-corners w-100"
                  to={`/${i18n.language}/products/${link.slug}`}
                >
                  <CategoriesCard category={link} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductsCategories;
