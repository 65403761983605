import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";

// External imports
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import { Helmet } from "react-helmet-async";
import ButtonLoader from "../components/ButtonLoader";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const gtx = useContext(GlobalContext);
  const [firstName, setFirstName] = useState("");
  const [firstNameValid, setFirstNameValid] = useState(null);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameValid, setLastNameValid] = useState(null);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberValid, setPhoneNumberValid] = useState();
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [message, setMessage] = useState("");
  const [messageValid, setMessageValid] = useState(null);
  const [messageTouched, setMessageTouched] = useState(false);
  const [validData, setValidData] = useState({
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    email: email,
    description: message,
  });
  const [disabled, setDisabled] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [messageSuccessful, setMessageSuccessful] = useState(false);
  const [responseError, setResponseError] = useState("");

  useEffect(() => {
    gtx.setLoading(false);
  }, [gtx]);

  useEffect(() => {
    if (firstNameTouched === true) {
      firstName !== "" ? setFirstNameValid(true) : setFirstNameValid(false);
    }
  }, [firstName, firstNameTouched]);

  useEffect(() => {
    if (lastNameTouched === true) {
      lastName !== "" ? setLastNameValid(true) : setLastNameValid(false);
    }
  }, [lastName, lastNameTouched]);

  useEffect(() => {
    if (emailTouched === true) {
      // email !== "" ? setEmailValid(true) : setEmailValid(false);
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
  }, [email, emailTouched]);

  useEffect(() => {
    if (phoneNumberTouched === true) {
      phoneNumber && isPossiblePhoneNumber(phoneNumber)
        ? setPhoneNumberValid(true)
        : setPhoneNumberValid(false);
    }
  }, [phoneNumber, phoneNumberTouched]);

  useEffect(() => {
    if (messageTouched === true) {
      message !== "" ? setMessageValid(true) : setMessageValid(false);
    }
  }, [message, messageTouched]);

  useEffect(() => {
    setValidData({
      first_name: firstName,
      middle_name: "whatever",
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      description: message,
    });
  }, [email, firstName, lastName, message, phoneNumber]);

  const contactSubmitHandler = (e) => {
    e.preventDefault();
    if (firstName === "") {
      setFirstNameValid(false);
    } else {
      setFirstNameValid(true);
    }
    if (lastName === "") {
      setLastNameValid(false);
    } else {
      setLastNameValid(true);
    }
    if (email === "") {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
    if (phoneNumber === null) {
      setPhoneNumberValid(false);
    } else {
      setPhoneNumberValid(true);
    }
    if (message === "") {
      setMessageValid(false);
    } else {
      setMessageValid(true);
    }
    if (
      firstNameValid &&
      lastNameValid &&
      phoneNumberValid &&
      emailValid &&
      messageValid
    ) {
      gtx.setLoading(true);
      setDisabled(true);
      setRequestSent(true);
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "ContactUs", validData)
        .then((res) => {
          setMessageSuccessful(true);
          setDisabled(false);
          gtx.setLoading(false);
        })
        .catch((err) => {
          setMessageSuccessful(false);
          setDisabled(false);
          gtx.setLoading(false);
          setResponseError("Something went wrong! Please try again later.");
          throw err;
        });
      setFirstName("");
      setFirstNameValid(null);
      setFirstNameTouched(false);
      setLastName("");
      setLastNameValid(null);
      setLastNameTouched(false);
      setEmail("");
      setEmailValid(null);
      setEmailTouched(false);
      setPhoneNumber(null);
      setPhoneNumberValid();
      setPhoneNumberTouched(false);
      setMessage("");
      setMessageValid(null);
      setMessageTouched(false);
    } else {
      setMessageSuccessful(false);
    }
  };

  // Languages

  const { t } = useTranslation();

  return (
    <Layout active="contact_us">
      <Helmet>
        <title>{t("contactPage.name").toUpperCase()}</title>
      </Helmet>
      <div className="container py-5 flex-grow-1 my-auto">
        <div className="row">
          <div className="col-lg-7">
            <h1 className="text-uppercase contact-title">
              {t("contactPage.name")}
            </h1>
            <form className="contact-form" onSubmit={contactSubmitHandler}>
              <div className="row">
                <div className="col-sm-6 pt-sm-0 pt-3">
                  <label htmlFor="fname">{t("contactPage.firstNameTitle")}</label>
                  <input
                    id="fname"
                    type="text"
                    value={firstName}
                    placeholder="John"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                  />
                  {firstNameValid === false && (
                    <p className="contact-error-text mb-0">
                      {t("contactPage.firstNameErrorText")}
                    </p>
                  )}
                </div>

                <div className="col-sm-6 pt-sm-0 pt-3">
                  <label htmlFor="lname">{t("contactPage.lastNameTitle")}</label>
                  <input
                    id="lname"
                    type="text"
                    value={lastName}
                    placeholder="Doe"
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                  />
                  {lastNameValid === false && (
                    <p className="contact-error-text mb-0">
                      {t("contactPage.lastNameErrorText")}
                    </p>
                  )}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12">
                  <label htmlFor="email">{t("contactPage.emailTitle")}</label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    placeholder="example@example.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailTouched(true);
                    }}
                  />
                  {emailValid === false && (
                    <p className="contact-error-text mb-0">{t("contactPage.emailErrorText")}</p>
                  )}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12">
                  <label htmlFor="phone">{t("contactPage.phoneNumberTitle")}</label>
                  <PhoneInput
                    id="phone"
                    className="contact-phone"
                    placeholder={t("contactPage.phonePlaceholder")}
                    withCountryCallingCode
                    international
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e);
                    }}
                    onBlur={() => {
                      setPhoneNumberTouched(true);
                    }}
                  />
                  {phoneNumberValid === false && (
                    <p className="contact-error-text mb-0">
                      {t("contactPage.phoneNumberErrorText")}
                    </p>
                  )}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12 d-flex flex-column">
                  <label htmlFor="description">{t("contactPage.messageTitle")}</label>
                  <textarea
                    id="description"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageTouched(true);
                    }}
                  />
                  {messageValid === false && (
                    <p className="contact-error-text mb-0">
                      {t("contactPage.messageErrorText")}
                    </p>
                  )}
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12 d-flex flex-column">
                  <button
                    disabled={disabled}
                    type="submit"
                    className="contact-button py-1"
                  >
                    {disabled ? <ButtonLoader /> : t("contactPage.sendMessageTitle")}
                  </button>
                  {requestSent && messageSuccessful && (
                    <p className="mb-0 pt-2 success-message">
                      {t("contactPage.successMessage")}
                    </p>
                  )}
                  {responseError !== "" && (
                    <p className="contact-error-text mb-0 pt-2">
                      {responseError}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-5 pt-lg-0 pt-4">
            <div className="contact-img h-100 w-100">
              <img
                src="https://d3mvlb3hz2g78.cloudfront.net/wp-content/uploads/2018/01/thumb_720_450_Gearsdreamstime_xl_89463027.jpg"
                alt="gears"
                className="cover h-100 w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
