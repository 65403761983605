export const Spanish = {
  menu: {
    home_page: "página de inicio",
    products: "productos",
    contact_us: "Contáctenos",
    not_found: "página no encontrada (404)",
  },
  products: {
    noProd: "¡No se encontraron productos!",
  },
  contactPage: {
    name: "Contacta con nosotros",
    firstNameTitle: "Primer nombre",
    lastNameTitle: "Ultimo Nombre",
    emailTitle: "email",
    phoneNumberTitle: "número de teléfono",
    messageTitle: "mensaje",
    firstNameErrorText: "Por favor, introduzca su nombre de pila",
    lastNameErrorText: "Por favor ingrese su último nombre",
    emailErrorText:
      "Por favor introduzca una dirección de correo electrónico válida",
    phoneNumberErrorText: "Por favor ingrese un número de teléfono válido",
    messageErrorText: "Por favor ingrese un mensaje",
    sendMessageTitle: "Enviar mensaje",
    successMessage: "Su mensaje fue enviado exitosamente!",
    phonePlaceholder: "Ingresa número telefónico",
  },
  footer: {
    copyrights: "Este sitio web y sus contenidos son copyright",
  },
  map: {
    location: "nuestra ubicación en",
  },
  search: {
    placeHolder: "Búsqueda...",
  },
};
