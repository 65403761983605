import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import GlobalContext from "../GlobalContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import axios from "axios";
import { Helmet } from "react-helmet-async";
import locationIcon from "../assets/images/location-icon.svg";
import { useTranslation } from "react-i18next";

const Home = () => {
  const gtx = useContext(GlobalContext);
  // const [newsData, setNewsData] = useState({ data: [] });
  const [profileData, setProfileData] = useState([]);
  const [swiperData, setSwiperData] = useState({ data: [{ image: "" }] });
  const [showLoader, setShowLoader] = useState(gtx.loading);
  const [LocationData, setLocationData] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "company_info")
      .then((res) => {
        setProfileData(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        throw err;
      });
    // axios
    //   .get(process.env.REACT_APP_BACKEND_URL + "home_page_news")
    //   .then((res) => {
    //     setNewsData(res.data);
    //     setShowLoader(false);
    //   })
    //   .catch((err) => {
    //     setShowLoader(false);
    //     throw err;
    //   });
  }, []);

  useEffect(() => {
    if (showLoader === true) {
      gtx.setLoading(true);
    } else {
      gtx.setLoading(false);
    }
  }, [showLoader, gtx]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "carousel_images")
      .then((res) => {
        setSwiperData(res.data);
      })
      .catch((err) => {
        throw err;
      });
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "location")
      .then((res) => {
        setLocationData(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        throw err;
      });
  }, []);

  const { i18n, t } = useTranslation();

  return (
    <Layout active="home_page">
      <Helmet>
        <title>CAPITANA ET HIJOS</title>
        <meta
          name="description"
          content="We provide high-quality products for European, American, Korean & Japenese Cars & Trucks parts"
        />
      </Helmet>
      <div className="container-fluid px-0 home-banner">
        <Swiper
          // navigation={true}
          pagination={{
            clickable: true,
            el: ".home-bullets",
          }}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          grabCursor
          modules={[Navigation, Pagination, Autoplay]}
          className="home-slider"
        >
          {swiperData.data.map((link, index) => (
            <SwiperSlide key={index}>
              <div className="ratio ratio-slider-home">
                <img
                  src={process.env.REACT_APP_IMAGES_URL + link.image}
                  alt="gears"
                  className="cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="bullets-container py-3">
          <div className="home-bullets d-flex justify-content-center"></div>
        </div>
      </div>
      {/* <div className="container pb-5">
        <div className="row">
          {profileData.map((link, index) => (
            <AboutCard
              key={index}
              title={link.title}
              description={link.info}
              isOpen={index === 0 && true}
              index={index}
            />
          ))}
        </div>
      </div> */}
      <div className="container py-5 flex-grow-1">
        <div className="row mx-sm-0 mx-3">
          {profileData.map((link, index) => (
            <div
              key={index}
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              className={`col-12 pe-0 home-item ${
                index !== profileData.length - 1 ? "mb-5" : ""
              }`}
            >
              <p className="text-uppercase">{link[`${i18n.language}`].title}</p>
              <div className="home-item-description custom-scroll py-4 px-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: link[`${i18n.language}`].info,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        {LocationData.map((link, index) => (
          <div key={index} className="row pb-5">
            <div className="col-12 px-4 px-sm-2">
              <div className="about-profile-wrapper h-100">
                <div className="py-2 px-3 about-profile-title d-flex justify-content-between align-items-center">
                  <h3 className="text-uppercase mb-0">
                    {t("map.location")}&nbsp;
                    {link[`${i18n.language}`].country}
                  </h3>
                </div>
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="d-flex flex-column justify-content-end h-100">
                      <div className="ratio ratio-1x1 location-icon-about">
                        <img
                          src={locationIcon}
                          alt="location"
                          className="contain"
                        />
                      </div>
                      <p className="location-text-about pt-4 text-capitalize">
                        <span className="text-uppercase">
                          <b>{link[`${i18n.language}`].name}</b>
                        </span>
                        <br />
                        {link[`${i18n.language}`].street} <br /> {link[`${i18n.language}`].city}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 pt-lg-0 pt-4">
                    <div className="ratio ratio-16x9">
                      <iframe
                        title="map"
                        src={
                          "https://maps.google.com/maps?q=" +
                          link.longitude +
                          "," +
                          link.latitude +
                          `&hl=${i18n.language}&z=14&output=embed`
                        }
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Home;
