import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const ProductsCard = (props) => {
  const { i18n } = useTranslation();
  return (
    <Fragment>
      <div className="image-title-prod h-100 p-3">
        <div className="ratio ratio-4x3">
          <img
            src={process.env.REACT_APP_IMAGES_URL + props.category.image}
            alt="category"
            className="cover"
          />
        </div>
        <p className="product-title-prod pt-3 text-break">
          {props.category[`${i18n.language}`].title || props.category[`${i18n.language}`].name}
        </p>
      </div>
    </Fragment>
  );
};

export default ProductsCard;
