import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { English } from "./English";
import { French } from "./French";
import { Spanish } from "./Spanish";

const resources = {
  en: {
    translation: English,
  },
  fr: {
    translation: French,
  },
  es: {
    translation: Spanish,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  supportedLngs: ["en", "fr", "es"],
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
