import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const ProductsCard = (props) => {
  const { i18n } = useTranslation();
  return (
    <Fragment>
      <div className="image-title-prod p-3 flex-grow-1 d-flex flex-column">
        <div className="ratio ratio-4x3">
          <img
            src={
              process.env.REACT_APP_IMAGES_URL +
              (props.product.image || props.product.images[0]?.image)
            }
            alt="product"
            className="cover"
          />
        </div>
        <p className="product-title-prod pt-3 text-break">
          {props.product[`${i18n.language}`].title ||
            props.product[`${i18n.language}`].name}
        </p>
      </div>
      {props.product[`${i18n.language}`].description && (
        <div className="product-description-prod px-3 py-4 d-flex justify-content-center align-items-center">
          <p className="text-break zindex-10">
            {props.product[`${i18n.language}`].description}
          </p>
        </div>
      )}
    </Fragment>
  );
};

export default ProductsCard;
