import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoWarningOutline } from "react-icons/io5";
import Layout from "../components/Layout";
import GlobalContext from "../GlobalContext";

const NotFound = () => {
  const { setLoading } = useContext(GlobalContext);
  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const { t } = useTranslation();

  return (
    <Layout active="not_found">
      <Helmet>
        <title>{t('menu.not_found').toUpperCase()}</title>
      </Helmet>
      <div className="flex-grow-1 d-flex">
        <div className="container my-auto">
          <div className="row justify-content-center">
            <div className="col-md-4 col-6">
              <div className="ratio ratio-1x1">
                <IoWarningOutline style={{ color: "#1664a5" }} />
              </div>
              <p className="success-message text-center d-lg-block d-none text-capitalize">{t("menu.not_found")}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
