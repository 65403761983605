// General imports
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import GlobalContext from "./GlobalContext";
import { HelmetProvider } from "react-helmet-async";
// import i18n from "./i18n";
import { useTranslation } from "react-i18next";

// Components imports
import Home from "./pages/Home";
import { useEffect, useState } from "react";
// import About from "./pages/About";
import SingleProduct from "./pages/SingleProduct";
import ContactUs from "./pages/ContactUs";
import ScrollToTop from "./components/ScrollToTop";
import ProductsCategories from "./pages/ProductsCategories";
import Products from "./pages/Products";
import NotFound from "./pages/NotFound";

function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    navigate(`/${i18n.language}${location.pathname.slice(3)}`);
  }, [i18n.language]); //eslint-disable-line

  return (
    <GlobalContext.Provider value={{ loading, setLoading }}>
      <HelmetProvider>
        <ScrollToTop>
          <Routes>
            <Route exact path="/:lang/" element={<Home />} />
            {/* <Route path="about" element={<About />} /> */}
            <Route
              exact
              path="/:lang/products"
              element={<ProductsCategories />}
            />
            <Route exact path="/:lang/products/:slug" element={<Products />} />
            <Route
              exact
              path="/:lang/products/:slug/:prodName"
              element={<SingleProduct />}
            />
            <Route exact path="/:lang/contact-us" element={<ContactUs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
      </HelmetProvider>
    </GlobalContext.Provider>
  );
}

export default App;
