export const English = {
  menu: {
    home_page: "Home",
    products: "Products",
    contact_us: "Contact Us",
    not_found: "Page not found (404)",
  },
  products: {
    noProd: "No products found!"
  },
  contactPage: {
    name: "Contact Us",
    firstNameTitle: "First Name",
    lastNameTitle: "last name",
    emailTitle: "email",
    phoneNumberTitle: "phone number",
    messageTitle: "Message",
    firstNameErrorText: "Please enter your first name",
    lastNameErrorText: "Please enter your last name",
    emailErrorText: "Please enter a valid email",
    phoneNumberErrorText: "Please enter a valid phone number",
    messageErrorText: "Please enter a message",
    sendMessageTitle: "Send Message",
    successMessage: "Your message was sent successfully!",
    phonePlaceholder: "Enter Phone Number",
  },
  footer: {
    copyrights: "This website and its contents are copyright",
  },
  map: {
    location: "our location in",
  },
  search: {
    placeHolder: "Search...",
  },
};
