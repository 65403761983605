import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { useParams } from "react-router-dom";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import { Helmet } from "react-helmet-async";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useTranslation } from "react-i18next";

const SingleProduct = () => {
  const slug = useParams();
  const [data, setData] = useState([]);
  const gtx = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(gtx.loading);
  const { i18n } = useTranslation();
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL + "getProductBySlug/" + slug.prodName
      )
      .then((res) => {
        setData(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        throw err;
      });
  }, [slug.prodName]);

  useEffect(() => {
    if (showLoader === true) {
      gtx.setLoading(true);
    } else {
      gtx.setLoading(false);
    }
  }, [showLoader, gtx]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const swiperRef = useRef();
  // useEffect(() => {
  //   swiperRef.current.swiper.slideToLoop(0, 0);
  // }, []);
  // swiperRef.current?.swiper.slideToLoop(0, 0);

  // const swiperSlideReset = () => {
  //   swiperRef.current?.swiper.slideToLoop(0, 0);
  // };

  useEffect(() => {
    data.length !== 0 && setPageName(data[`${i18n.language}`].name?.toUpperCase());;
  }, [data, i18n.language]);

  return (
    <Layout active="products">
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <div className="container flex-grow-1 py-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <div className="d-flex flex-column justify-content-center h-100">
              <h1 className="text-uppercase title-singprod">
                {data[`${i18n.language}`]?.name}
              </h1>
              <p className="description-singprod">
                {data[`${i18n.language}`]?.description}
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-9 col-sm-11 col-12 pt-3 position-relative d-flex flex-column justify-centent-center">
            <div className="position-relative my-auto">
              <Swiper
                // navigation={{
                //   nextEl: ".singprod-next-button",
                //   prevEl: ".singprod-prev-button",
                // }}
                ref={swiperRef}
                pagination={{
                  clickable: true,
                  el: ".singprod-bullets",
                }}
                autoplay={{
                  delay: 5000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                // loop={true}
                thumbs={{ swiper: thumbsSwiper }}
                grabCursor
                modules={[Navigation, Pagination, Thumbs, Autoplay]}
                spaceBetween={30}
                className="singprod-slider mx-sm-5"
              >
                {data?.images?.map((link, index) => (
                  <SwiperSlide key={index}>
                    <div className="ratio ratio-4x3 d-lg-block d-none">
                      <div className="singprod-slide-wrapper p-4">
                        <InnerImageZoom
                          src={process.env.REACT_APP_IMAGES_URL + link.image}
                          zoomSrc={
                            process.env.REACT_APP_IMAGES_URL + link.image
                          }
                          zoomScale={1.4}
                          zoomType="hover"
                          className="image-zoom h-100 w-100"
                        />
                      </div>
                    </div>
                    <div className="singprod-slide-wrapper p-4 d-lg-none">
                      <div className="ratio ratio-4x3">
                        <img
                          src={process.env.REACT_APP_IMAGES_URL + link.image}
                          alt="parts"
                          className="cover"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {data?.images?.length > 1 && (
                <Swiper
                  onSwiper={setThumbsSwiper}
                  grabCursor
                  modules={[FreeMode, Thumbs]}
                  freeMode={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  className={`singprod-slider-thumbs mx-sm-5 pt-3 ${
                    data?.images?.length < 4 ? "centered-slides" : ""
                  }`}
                >
                  {data?.images?.map((link, index) => (
                    <SwiperSlide key={index}>
                      <div className="ratio ratio-1x1">
                        <img
                          src={process.env.REACT_APP_IMAGES_URL + link.image}
                          alt="parts"
                          className="cover"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              {/* <div className="singprod-nav-buttons d-sm-block d-none">
                <div className="singprod-next-button"></div>
                <div className="singprod-prev-button"></div>
              </div> */}
              <div className="singprod-bullets-container py-3">
                <div className="singprod-bullets d-flex justify-content-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SingleProduct;
