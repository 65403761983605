import "aos/dist/aos.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import Layout from "../components/Layout";
import searchIcon from "../assets/images/search-icon.svg";
import ProductsCard from "../components/ProductsCard";
import AOS from "aos";
import axios from "axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import GlobalContext from "../GlobalContext";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const Products = (props) => {
  const slug = useParams();
  const gtx = useContext(GlobalContext);
  const { i18n, t } = useTranslation();

  const [showLoader, setShowLoader] = useState(gtx.loading);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useSearchParams();
  const [pagesNumber, setPagesNumber] = useState(1);
  const firstVisit = useRef(true);
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);

  const searchSubmitHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "category/" +
          slug.slug +
          "/products?" +
          search
      )
      .then((res) => {
        setData(res.data.data);
        setPagesNumber(res.data.data.last_page);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        throw err;
      });
  }, [slug.slug, search]);

  useEffect(() => {
    if (showLoader === true) {
      gtx.setLoading(true);
    } else {
      gtx.setLoading(false);
    }
  }, [showLoader, gtx]);

  const searchChangeHandler = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (firstVisit.current === true) {
      // prevent running on first render
      firstVisit.current = false;
    } else {
      const searchTimeOut = setTimeout(() => {
        setSearch({ page: 1, product: searchText, lang: i18n.language });
      }, 1000);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText, setSearch, i18n.language]);

  useEffect(() => {
    data.length !== 0 &&
      setPageTitle(data[`${i18n.language}`].name?.toUpperCase());
  }, [data, i18n.language]);

  return (
    <Layout active="products">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="container pt-5 flex-grow-1">
        <div className="row justify-content-center pb-5">
          <div className="col-lg-4 col-md-6 px-sm-4 px-3">
            <form onSubmit={searchSubmitHandler} className="position-relative">
              <input
                className="search-bar py-1 ps-5 pe-1 w-100"
                type="text"
                placeholder={t("search.placeHolder")}
                value={searchText}
                onChange={(e) => searchChangeHandler(e)}
              ></input>
              <button
                type="submit"
                className="position-absolute top-0 start-0 h-100 search-button-prods ms-2 search-button-w"
              >
                <div className="ratio ratio-1x1 w-100">
                  <img src={searchIcon} alt="search" className="contain" />
                </div>
              </button>
            </form>
          </div>
          {data.products?.length !== 0 ? (
            <div
              data-aos="fade-right"
              data-aos-duration="800"
              className="row gx-sm-4 gx-lg-5 gx-3 pt-4"
            >
              {data.products?.map((link, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 pt-4" key={index}>
                  <Link
                    className="text-decoration-none d-flex flex-column h-100 single-prod-link rounded-corners w-100"
                    to={`/${i18n.language}/products/${slug.slug}/${link.slug}`}
                  >
                    <ProductsCard product={link} />
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <p className="success-message pt-5">{t("products.noProd")}</p>
          )}
          {pagesNumber > 1 && (
            <div className="col-12 pt-5">
              <div className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  previousLinkClassName={"pagination-previous"}
                  containerClassName={"pagination-container"}
                  nextLinkClassName={"pagination-next"}
                  activeClassName={"pagination-active"}
                  disabledClassName={"pagination-disabled"}
                  onPageChange={(page) =>
                    setSearch({ page: page.selected + 1, product: searchText })
                  }
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  pageCount={pagesNumber}
                  forcePage={data.current_page - 1}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Products;
